import { Grid } from "@mui/material";
import { isNil } from "lodash";
import { ReactNode } from "react";
import { columnSpacing } from "../../../../theme/Theme";
import InViewAnimationGrid from "../../../behaviors/InViewAnimation/InViewAnimationGrid";
import RichText from "../../../elements/RichText/RichText";

export type ContentBProps = {
  title?: string;
  body: string;
  children?: ReactNode;
};

const ContentB = ({ title, body, children }: ContentBProps) => {
  return (
    <InViewAnimationGrid
      container
      columnSpacing={columnSpacing}
      rowSpacing={{ xs: 2, sm: 4 }}
    >
      {!isNil(title) && (
        <Grid item xs={12}>
          <RichText
            text={title}
            textProps={{
              sx: {
                marginBottom: "0px !important",
                "& ol": {
                  margin: 0,
                  listStyle: "auto inside",
                },
              },
            }}
          />
        </Grid>
      )}
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
      {!isNil(body) && (
        <Grid item xs={12}>
          <RichText text={body} />
        </Grid>
      )}
    </InViewAnimationGrid>
  );
};

export default ContentB;
