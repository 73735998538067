import { Grid } from "@mui/material";
import { isNil } from "lodash";
import Link from "../../elements/Link/Link";
import Text from "../../elements/Text/Text";

type ClickableTextGroupProps = {
  items: ClickableTextGroupItem[];
};

type ClickableTextGroupItem = {
  id: string;
  text: string;
  to?: string;
  onClick?: (id: string) => void;
};

const ClickableTextGroup = ({ items }: ClickableTextGroupProps) => {
  return (
    <Grid
      container
      item
      xs={12}
      columnSpacing={{ xs: 2, sm: 3 }}
      rowSpacing={{ xs: 2, sm: 3 }}
      justifyContent="center"
      sx={{
        paddingLeft: {
          sx: 1,
          sm: 2,
        },
        paddingRight: {
          sx: 1,
          sm: 2,
        },
      }}
    >
      {items.map(({ id, text, to, onClick }) => (
        <Grid item key={id}>
          <Link
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              textAlign: "center",
              color: (theme) => theme.palette.secondary.main,
              display: "block",
            }}
            to={to}
            onClick={() => !isNil(onClick) && onClick(id)}
          >
            <Text variant="h2" component="p" sx={{ marginBottom: 0 }}>
              {text}
            </Text>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default ClickableTextGroup;
