import { Grid } from "@mui/material";
import { isNil } from "lodash";
import { ImageData } from "../../../../utils/image/buildImageData";
import InViewAnimationGrid from "../../../behaviors/InViewAnimation/InViewAnimationGrid";
import Divider from "../../../elements/Divider/Divider";
import Image from "../../../elements/Image/Image";
import Text from "../../../elements/Text/Text";
import ClickableTextGroup from "../../ClickableTextGroup/ClickableTextGroup";
import ContentB from "./ContentB";

type ContentBGroupProps = {
  data: Record<string, ContentBGroupItemData[]>;
};

type ContentBGroupItemData = {
  year: string;
  title: string;
  body: string;
  imageData: ImageData;
};

const ContentBGroup = ({ data }: ContentBGroupProps) => {
  const headings = Object.keys(data);

  return (
    <Grid sx={{ behavior: "smooth" }} container item rowSpacing={{ xs: 2 }}>
      <InViewAnimationGrid item xs={12}>
        <ClickableTextGroup
          items={headings.map((heading) => ({
            id: heading,
            text: heading,
            to: `#${heading}`,
          }))}
        />
      </InViewAnimationGrid>
      {headings.map((heading) => (
        <InViewAnimationGrid
          container
          item
          key={heading}
          id={heading}
          rowSpacing={{ xs: 2 }}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: { xs: 2 } }}>
            <Text variant="h2">{heading}</Text>
          </Grid>
          <Grid item xs={12}>
            {data[heading].map(({ title, imageData, body }, index) => (
              <>
                {index !== 0 && (
                  <Divider
                    sx={{
                      marginBottom: {
                        xs: 3,
                      },
                      marginTop: {
                        xs: 3,
                      },
                    }}
                  />
                )}
                <ContentB key={body} title={title} body={body}>
                  {!isNil(imageData.image) && (
                    <Image image={imageData.image} alt={imageData.alt} />
                  )}
                </ContentB>
              </>
            ))}
          </Grid>
        </InViewAnimationGrid>
      ))}
    </Grid>
  );
};

export default ContentBGroup;
