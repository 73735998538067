import { useMediaQuery, useTheme } from "@mui/material";
import { groupBy, isEmpty } from "lodash";
import { useMemo } from "react";
import { buildImageData } from "../../../utils/image/buildImageData";
import ContentBGroup from "../../views/Content/ContentB/ContentBGroup";

type CMSContentBGroupProps = {
  data: GatsbyTypes.CompanyPageQuery["prismicCompanyPage"]["data"]["projects"];
};

const CMSContentBGroup = ({ data }: CMSContentBGroupProps) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const builtData = useMemo(
    () =>
      groupBy(
        data?.map(({ image, image_mobile, title, body, year }) => ({
          title: title?.html,
          body: body?.html,
          imageData: isDesktop
            ? buildImageData(image)
            : buildImageData(image_mobile),
          year,
        })),
        (item) => item.year
      ),
    [data, isDesktop]
  );

  if (isEmpty(builtData)) {
    return null;
  }

  return <ContentBGroup data={builtData} />;
};

export default CMSContentBGroup;
