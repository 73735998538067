import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export type DividerProps = MuiDividerProps;

const StyledDivider = styled(MuiDivider)({});

const Divider = ({ sx, ...restProps }: DividerProps) => {
  return (
    <StyledDivider
      sx={{
        borderBottomWidth: {
          xs: 4,
          sm: 6,
        },
        borderColor: (theme) => theme.palette.secondary.main,
        ...sx,
      }}
      {...restProps}
    />
  );
};

export default Divider;
